import React from 'react'
import Header from '../header'
import axios from 'axios';
import config from '../config'
// import Blackfont from '../assets/images/alphabets/BLACK-FONT/A.png'
// import Blueredfont from '../assets/images/alphabets/BLUE-RED-FONT/A.png'
// import Londonfont from '../assets/images/alphabets/LONDON-FONT/A.png'
import { ToastContainer, toast } from 'react-toastify';
class Media extends React.Component{
    constructor(props)
    {
        super(props);
        this.state={
            data:[],
            show:false,
            uploadimage:[],
            media :'',
            uploadredirect:[],
            selectedItems:[]
        }
        this.addmedia = this.addmedia.bind(this);
        this.closeaddmedia = this.closeaddmedia.bind(this);
        this.medialist = this.medialist.bind(this);
        this.uploadimage = this.uploadimage.bind(this);
        this.upload = this.upload.bind(this);
        this.deleteImages = this.deleteImages.bind(this);
        this.selectedDelete = this.selectedDelete.bind(this);
    }
    selectedDelete(selectedItems)
    {
        //console.log("selectedItems",selectedItems)
        if (window.confirm("Delete the Image(s)?")) {
        let assests_ids = selectedItems.map(e => e.id).join(",");
        let apiDynamicUrlAddress = config.default.api.host;
          let apiUrl = `${apiDynamicUrlAddress}deleteAssests`
          var formData = new FormData();
          formData.append("deleteAssests",1)
          formData.append("assests_ids",assests_ids)
              axios.post(apiUrl ,formData, {
              crossDomain : true,
              enablePreflight : false
          }).then((response) => {
            if(response.data.success == 1)
            {
              //console.log("response",response)
              this.medialist(this.state.media);
            }
          })
          .catch((err) => {
          });
        }
    }
    deleteImages(e)
    {
        const {selectedItems } = this.state;
        //console.log("e",e.id)
        let id = e.id
        let itemid=[];
        let isExist=selectedItems.filter(x=>x.id==id);
        if(isExist.length > 0){
            itemid = selectedItems.filter(x=>x.id!=id);
            selectedItems.length = 0; 
            selectedItems.push.apply(selectedItems, itemid);
          }
          else{
            selectedItems.push({
                id:e.id,
            })
          }
          this.setState({
              tempstate:selectedItems
          })
    }
    uploadimage(e)
    {
        const {uploadimage} = this.state;
        //console.log("e",e.target.files[0])
        this.setState({ uploadimage: [...this.state.uploadimage, ...e.target.files] })
    }
    upload(e)
    {
        const {uploadredirect} = this.state;
        for  (var i =  0; i < this.state.uploadimage.length; i++)  {  
            e.preventDefault();
            // console.log("e",e)
            // console.log("this.state.media",this.state.media)
            // console.log("uplaodimage",this.state.uploadimage)
            let store_id = sessionStorage.getItem('store_id')
            let apiDynamicUrlAddress = config.default.api.host;
            let apiUrl = `${apiDynamicUrlAddress}addAssests`
            const headers = {
                'Content-Type': 'multipart/form-data',
            }
            var formData = new FormData();
            formData.append("image_type",this.state.media) 
            formData.append("store_id",store_id)
            formData.append("uploadimage",this.state.uploadimage[i],this.state.uploadimage[i].name)
                axios.post(apiUrl ,formData, {
                headers:headers,
                crossDomain : true,
                enablePreflight : false
            }).then((response) => {
                if(response.data.success == 1)
                {
                    uploadredirect.push(true);
                    this.uploadredirect(this.state.uploadimage , uploadredirect, this.state.media)
                }
                else{
                    toast.error("Upload Failed", {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000
                    });
                }
            })
            .catch((err) => {
    
            });
        }
    }
    uploadredirect(uploadimage , uploadredirect, media)
    {
        // console.log("uploadimage.length",uploadimage.length)
        // console.log("uploadredirect.length",uploadredirect.length)
        if(uploadimage.length == uploadredirect.length)
        {
            toast.success("Uploaded Successfully", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000
            });
            this.setState({
                media: media
            })
            //this.props.history.push('/admin/assets/media')
            this.medialist(this.state.media);
            this.setState({
                uploadimage:[]
            })
        }
    }
    medialist(media)
    {
        this.props.history.push('/admin/assets/media')
        this.setState({
            show : true,
            media: media
        })
        let store_id = sessionStorage.getItem('store_id')
        let apiDynamicUrlAddress = config.default.api.host;
        let apiUrl = `${apiDynamicUrlAddress}getAssests?type=${media}&store_id=${store_id}`
        axios.get(apiUrl , {
            crossDomain : true,
            enablePreflight : false
        }).then((response) => {
            if(response.data.success == 1)
            {
                this.setState({data:response.data.data})
            }
        })
        .catch((err) => {

        });
    }
    componentDidMount()
    {
        this.medialist('vinyl-heat-transfer');
    }
    alphabetsmedialist(media)
    {
        this.setState({
            show : true,
            media: media
        })
        this.props.history.push('/admin/assets/media/alphabets')
    }
    addmedia()
    {
        this.setState({
            uploadredirect : []
        })
        this.props.history.push('/admin/assets/media/add')
    }
    closeaddmedia(media)
    {
        this.setState({
            media:media
        })
        this.props.history.push('/admin/assets/media')
    }
    render()
    {
        const {data, selectedItems,media} = this.state;
        return(
            <div>
                <Header/>
                <ToastContainer/>
                <section className="home-register-section gridview-outer-section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12 col-lg-12">
                                <div className="home-register-form-div dashboard-div">
                                    <div className="grid-view-full-section">
                                        <div className="grid-view-full-section-left">
                                            <h3>Design</h3>
                                                <div className="col-md-12 sec-center left">
                                                    <div className="design-menu-inner">
                                                        <ul>
                                                            <li><button onClick={()=>this.medialist('vinyl-heat-transfer')} class={media == 'vinyl-heat-transfer' ? "active-tab" : ""}>Vinyl heat transfer</button></li>
                                                            <li><button onClick={()=>this.medialist('iron-on-patches')} class={media == 'iron-on-patches' ? "active-tab" : ""}>Iron on patches</button></li>
                                                            <li><button onClick={()=>this.medialist('hand-held-gun')} class={media == 'hand-held-gun' ? "active-tab" : ""}>Hand held gun</button></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            {this.props.location.pathname == '/admin/assets/media' && this.state.show? 
                                            <div className="grid-view-section-right">
                                                <h3>Media Library</h3>
                                                    <div className="dashboard-top-section">
                                                        <div className="dashboard-top-section-left">
                                                            <div className="add-btn-div">
                                                                <button className="additem_btn" onClick={()=> this.addmedia()}>Add items</button>
                                                            </div>
                                                            <div className="add-btn-div">
                                                                <button className="additem_btn" onClick={()=> this.selectedDelete(this.state.selectedItems)} >Delete</button>
                                                            </div>
                                                        </div>
                                                        <div className="dashboard-top-section-right">
                                                            {/* <div className="search-form-div">
                                                                <form>
                                                                    <input type="search" name="search" placeholder="search.."/>
                                                                </form>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                    <div className="medialibrary-grid-section">
                                                        {data && data.map((item, index)=>{
                                                        return(
                                                            <div className={selectedItems.filter(x=>x.id==item.id).length > 0 ? "medialibrary-box active" : "medialibrary-box"} key={index}>
                                                                <img src={item.image_path} alt="logo" className="img-fluid" onClick={() => this.deleteImages(item)}/>
                                                            </div>
                                                            )
                                                        })}
                                                        </div>
                                                </div>
                                                :
                                                this.props.location.pathname == '/admin/assets/media/add' ? 
                                                <div className="grid-view-section-right mediaadd-view-section-right">
                                                    <div className="add-media-title">
                                                        <button className="bottom-arrow-btn close" onClick={() => this.closeaddmedia(this.state.media)}>
                                                            <i className="fa fa-times" aria-hidden="true"></i>
                                                        </button>
                                                        <h4>Add media files from your computer</h4>
                                                            <p>Allowed file type: jpg, jpeg, png, gif, pdf, doc, docs</p>
                                                    </div>
                                                     <div className="add-media-div">
                                                        <form id="upload" encType="multipart/form-data">
                                                            <fieldset>
                                                                <input type="hidden" id="MAX_FILE_SIZE" name="MAX_FILE_SIZE" value="300000" />
                                                                <div>
                                                                    <label htmlFor="fileselect">Select a file to upload
                                                                    <br />OR
                                                                    <br />Drag a file into this box</label>
                                                                    <div className="upload_file_div">
                                                                        <input type="file" id="fileselect" name="fileselect[]" multiple="multiple" onChange={this.uploadimage}/>
                                                                    </div>
                                                                    <div id="filedrag">Select a file to upload
                                                                        <br />OR
                                                                        <br />Drag a file into this box
                                                                    </div>
                                                                </div>
                                                                <div class="uploadsubmit-button-sec">
                                                                    <button type="submit" className="btn register-btn" onClick={this.upload}>Upload</button>
                                                                </div>
                                                            </fieldset>
                                                        </form>
                                                    </div>
                                                </div>
                                                :
                                                null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
        )
    }
}
export default Media;