import { Route , Redirect} from 'react-router-dom';
import React from 'react';
import ManageadminLogin from '../admin/manageadminlogin'
import Dashboardqueing from '../admin/dashboard-queingjobs'
import Dashboardcompleted from '../admin/dashboard-completedjobs'
import Media from '../admin/media'
export default () => [
   (window.location.hash == '#/retail01' || window.location.hash =='#/retail02') ? window.location.href="https://www.adidas.com.sg/" : null,
    <Route key="login" path="/admin/retail01" component={ManageadminLogin}/>,
    <Route key="login" path="/admin/retail02" component={ManageadminLogin}/>,
    <Route key="login" path="/admin/assets/retail01" component={ManageadminLogin} />,
    <Route key="login" path="/admin/assets/retail02" component={ManageadminLogin} />,
    <Route key="dashboard" path="/dashboard/queuing-jobs" render={props => (
      sessionStorage.getItem('admin_token')
           ? <Dashboardqueing {...props} />
           : <Redirect to={{ pathname: '/admin/'+sessionStorage.getItem('store')}} />
   )} />,
   <Route key="dashboard" path="/dashboard/completed-jobs" render={props => (
      sessionStorage.getItem('admin_token')
       ? <Dashboardcompleted {...props} />
       : <Redirect to={{pathname: '/admin/'+sessionStorage.getItem('store')}} />
    )} />,
    /* assets admin */
    <Route key="media" path="/admin/assets/media" render={props => (
      sessionStorage.getItem('assets_token')
           ? <Media {...props} />
           : <Redirect to={{ pathname: '/admin/assets/'+sessionStorage.getItem('store')}} />
    )} />,
   <Route key="alphabetsmedia" path="/admin/assets/media/alphabets" render={props => (
      sessionStorage.getItem('assets_token')
         ? <Media {...props} />
         : <Redirect to={{pathname: '/admin/assets/'+sessionStorage.getItem('store')}} />
    )} />,
   <Route key="addmedia" path="/admin/assets/media/add" render={props => (
      sessionStorage.getItem('assets_token')
       ? <Media {...props} />
       : <Redirect to={{ pathname: '/admin/assets/'+sessionStorage.getItem('store')}} />
    )} />,
];
