import React from 'react'
import iconsquare from '../../assets/images/icon-square.png'
import Header from '../../header'
import axios from 'axios';
import config from '../../config'
class Vinylheattransfer extends React.Component{
    constructor(props)
    {
        super(props)
        {
            this.state={
                data:[],
                logo:[],
                selectedItems:JSON.parse(sessionStorage.getItem('Vinylheattransfer')),
                codeerror:'',
                count : 1
            }
        }
        this.Vinylheattransferback = this.Vinylheattransferback.bind(this)
        this.Vinylheattransfernext = this.Vinylheattransfernext.bind(this)
        this.selectVinylheattransfer = this.selectVinylheattransfer.bind(this)
        this.storeCode = this.storeCode.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(e)
    {
        
        let name=e.target.name
        let value=e.target.value
        this.setState({[name]:value})
    }
    storeCode()
    {
        let apiDynamicUrlAddress = config.default.api.host;
        let apiUrl = `${apiDynamicUrlAddress}storeAuth`
        var formData = new FormData();
        formData.append("store_auth", '1');
        formData.append("store_code", this.state.code);
            let customer_id = sessionStorage.getItem('customer_id')
            let store_id = sessionStorage.getItem('store_id')
            let material_id = ''
            let Vinylheattransfer = sessionStorage.getItem('Vinylheattransfer')
            let Irononpatches = sessionStorage.getItem('Irononpatches')
            let Handheldgun = sessionStorage.getItem('Handheldgun')
            let prev_image =  ''
            let print_image = ''
            let body = [{
                "Vinylheattransfer":Vinylheattransfer ? Vinylheattransfer : '',
                "Irononpatches":Irononpatches ? Irononpatches : '',
                "Handheldgun":Handheldgun ? Handheldgun : '',
            }]
            formData.append("Vinylheattransfer", Vinylheattransfer ? Vinylheattransfer : '');
            formData.append("customer_id", customer_id ? customer_id : '');
            formData.append("material_id", material_id ? material_id : '');
            formData.append("status",'order');
            formData.append("prev_image",  prev_image ?  prev_image : '');
            formData.append("print_image", print_image ? print_image : '');
            formData.append("placed_by",  store_id ? store_id : '');
            formData.append("processed_by",'');
            formData.append("json", JSON.stringify(body));
        axios.post(apiUrl , formData, {
            crossDomain : true,
            enablePreflight : false
        }).then((response) => {
            //console.log("response",response)
            if(response.data.success == 1)
            {
                this.setState({
                    codesuccess : 'Store Code is valid'
                })
                setTimeout(() => {
                    this.props.history.push('/confirmation');
                }, 1000);
        }
        else{
            this.setState({
                codeerror : 'Store Code is invalid'
            })
        }
        })
        .catch((err) => {

        });
    }
    Vinylheattransferback()
    {
        this.props.history.push('/design-menu');
    }
    Vinylheattransfernext()
    {
        this.props.history.push('/iron-on-patches');
    }
    componentDidMount()
    {
        let store_id = sessionStorage.getItem('store_id')
        let apiDynamicUrlAddress = config.default.api.host;
        let apiUrl = `${apiDynamicUrlAddress}getAssests?type=vinyl-heat-transfer&store_id=${store_id}`
        axios.get(apiUrl , {
            crossDomain : true,
            enablePreflight : false
        }).then((response) => {
            if(response.data.success == 1)
            {
                this.setState({data:response.data.data})
            }
        })
        .catch((err) => {

        });
    }
    selectVinylheattransfer(e)
    {
        const {selectedItems } = this.state;
        let logoid= e.target.id
        // let itemid=[];
        // let isExist=selectedItems.filter(x=>x.id==logoid);
        // if(isExist.length > 0){
        //     itemid = selectedItems.filter(x=>x.id!=logoid);
        //     selectedItems.length = 0; 
        //     selectedItems.push.apply(selectedItems, itemid);
        //   }
        //   else{
        //     selectedItems.push({
        //         id:e.target.id,
        //         img:e.target.src
        //     })
        //   }
        selectedItems.push({
            id:e.target.id,
            img:e.target.src
        })
          this.setState({
              tempstate:selectedItems,
          })
          sessionStorage.setItem('Vinylheattransfer',JSON.stringify(selectedItems));
    }
    render()
    {
       const {selectedItems, codeerror, codesuccess, count}  = this.state;
       //console.log("codesuccess",codesuccess)
       let store = sessionStorage.getItem('store')
        return(
            <div>
                <Header/>
                <section className="home-register-section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12 col-lg-12">
                                <div className="home-register-form-div design-menu-sec design-logo-sec">
                                    <div className="form-logo-section sec-center">
                                        <a  href={'#/' + store}><img src={iconsquare} alt="logo" className="img-fluid brand-logo"/></a>
                                    </div>
                                    <h3>LOOKBOOK</h3>
                                    <h4>Vinyl heat transfer</h4>
                                    <div className="modal_error_button">
                                    <button type="button" class="confirm-btn" data-toggle="modal" data-target="#exampleModalCenter" disabled={selectedItems.length == 0}>
                                        ENTER STORE CODE
                                    </button>
                                    <div className="modal_error">
                                        <p>{codeerror}</p>
                                    </div>
                                    </div>
                                    <div class="modal fade confirm_modal" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                                        <div class="modal-dialog modal-dialog-centered" role="document">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h5 class="modal-title" id="exampleModalLongTitle">PLEASE ENTER STORE CODE</h5>
                                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div class="modal-body">
                                                    <input type="password"  
                                                        className="form-control" 
                                                        id="order-code" 
                                                        name="code" 
                                                        onChange={this.handleChange}
                                                        maxlength="6"
                                                        required/>
                                                </div>
                                                <div class="col-md-12 sec-center">
                                                    <button type="submit" class="register-btn" data-dismiss="modal"  onClick={() => this.storeCode()}>Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 sec-center left">
                                        <div className="design-logo-grid-outer">
                                            <div className="design-logo-grid-inner">
                                                {this.state.data && this.state.data.map((item, index)=>{
                                                    return(
                                                    <div key={index} className="logo-grid-box">
                                                        <div className="logo-grid-box-img" onClick={this.selectVinylheattransfer}>
                                                            <img id={item.id} src={item.image_path} className="img-fluid"/>
                                                        </div>
                                                    <div className="logo-grid-box-content">
                                                        <h5>Vinyl heat transfer {count + index}</h5>
                                                    </div>
                                                    <div className="logo-grid-box-content">
                                                        {/* <h5>Logo {index}</h5> */}
                                                    </div>
                                                 </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
	                                <div className="col-md-12 sec-center left">
	                                    <div className="footer-btn"> 
	                                        <div className="footer-left-btn"> 
	                                            <button onClick={this.Vinylheattransferback} className="black_btn">Back</button>
	                                        </div>
	                                        <div className="footer-right-btn"> 
	                                            <button onClick={this.Vinylheattransfernext} className="black_btn">Next</button>
	                                        </div>
	                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
export default Vinylheattransfer;