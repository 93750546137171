import React, { Suspense } from "react";
import {BrowserRouter, Switch, HashRouter} from "react-router-dom";
import Routes from "./shared/Routes";
import BrowserRoutes from './shared/BrowserRoutes'
import MobileRoutes from './shared/MobileRoutes'
import History from "./shared/history";
import {
  isIPad13,isMobile,isBrowser
} from "react-device-detect";
function App() {
  return (
    <div>
      <HashRouter history={History}>
        <Suspense fallback={<div></div>}>
          {isIPad13 ? <Switch>{Routes()}</Switch> : isMobile ?  <Switch>{MobileRoutes()}</Switch> :  isBrowser ?  <Switch>{BrowserRoutes()}</Switch> : null}
           {/* <Switch>{Routes()}</Switch> */}
        </Suspense>
      </HashRouter>
    </div>
  );
}

export default App;
