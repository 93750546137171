import React from 'react';
import iconsquare from '../assets/images/icon-square.png'
import Header from '../header'
import axios from 'axios';
import config from '../config'
import { ToastContainer, toast } from 'react-toastify';
class ManageadminLogin extends React.Component{
    constructor(props)
    {
        super(props)
        this.state={
            email:'',
            password:'',
        }
        this.handleSubmit= this.handleSubmit.bind(this);
        this.handleChange=this.handleChange.bind(this)
    }
    handleChange(e)
    {
        let name=e.target.name
        let value=e.target.value
        this.setState({[name]:value})
    }
    handleSubmit(e)
    {
        if(this.props.location.pathname == '/admin/retail01' || this.props.location.pathname == '/admin/retail02')
        {
        e.preventDefault();
        let apiDynamicUrlAddress = config.default.api.host;
        let apiUrl = `${apiDynamicUrlAddress}login`
        var formData = new FormData();
        let store_id =   sessionStorage.getItem('store_id')
        formData.append("is_ipad", '0');
        formData.append("email", this.state.email);
        formData.append("password", this.state.password);
        formData.append("store_id", store_id);
        axios.post(apiUrl , formData, {
            crossDomain : true,
            enablePreflight : false
        }).then((response) => {
        //console.log("response",response.data.auth_token)
        if(response.data.success == 1)
        {
            toast.success("Login Success", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000
            });
            let token = response.data.auth_token
            let user_id = response.data.session_id
            sessionStorage.setItem('admin_token',token)
            sessionStorage.setItem('user_id',user_id)
            this.props.history.push('/dashboard/queuing-jobs');
        }
        else{
            toast.error("Login Failed", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000
            });
        }
        })
        .catch((err) => {  
        });
    }
    else{
        e.preventDefault();
        let apiDynamicUrlAddress = config.default.api.host;
        let apiUrl = `${apiDynamicUrlAddress}loginAssests`
        var formData = new FormData();
        let store_id =   sessionStorage.getItem('store_id')
        formData.append("is_assests", '1');
        formData.append("email", this.state.email);
        formData.append("password", this.state.password);
        formData.append("store_id", store_id);
        axios.post(apiUrl , formData, {
            crossDomain : true,
            enablePreflight : false
        }).then((response) => {
        //console.log("response",response.data.auth_token)
        if(response.data.success == 1)
        {
            toast.success("Login Success", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000
            });
            let token = response.data.auth_token
            let user_id = response.data.session_id
            sessionStorage.setItem('assets_token',token)
            sessionStorage.setItem('user_id',user_id)
            this.props.history.push('/admin/assets/media');
        }
        else{
            toast.error("Login Failed", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000
            });
        }
        })
        .catch((err) => {  
        });
    }
    }
    componentDidMount()
    {
        if(this.props.location.pathname == '/admin/retail01' || this.props.location.pathname == '/admin/retail02')
        {
            document.title = "Adidas MakerLAB - Microsite - Staffs Admin"
        }
        else{
            document.title = "Adidas MakerLAB - Microsite - Manage Assets Admin"
        }
        sessionStorage.clear();
        var url = this.props.location.pathname;
        var store = url.substring(url.lastIndexOf('/') + 1);
        let apiDynamicUrlAddress = config.default.api.host;
        let apiUrl = `${apiDynamicUrlAddress}storeSlug`
        var formData = new FormData();
        formData.append("slug", '1');
        formData.append("slug_name", store);
        axios.post(apiUrl , formData, {
            crossDomain : true,
            enablePreflight : false
        }).then((response) => {
            //console.log("response",response)
        if(response.data.success == 1)
        {
            sessionStorage.setItem('store_id',response.data.data_id)
            sessionStorage.setItem('store',response.data.inserted_records.slug)
        }
        else{
            
        }
        })
        .catch((err) => {  
        });
    }
    render()
    {
        let Cstate=Object.values(this.state);
        let Istate =Cstate.map(x => x ?true:false).filter( x => x)
        // console.log("Cstate",Cstate)
        // console.log("Istate",Istate)
        //const {loginmessage} = this.state;
        //console.log("this.props",this.props.location.pathname)
        return(
            <div>
                <Header/>
                <ToastContainer/>
                <section className="home-register-section login-section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12 col-lg-12">
                                {this.props.location.pathname == '/admin/retail01' || this.props.location.pathname == '/admin/retail02' ? 
                                <div className="home-register-form-div">
                                    <div className="form-logo-section sec-center">
                                        <a><img src={iconsquare} alt="logo" className="img-fluid"/></a>
                                    </div>
                                    <h3 className="text-capitalize">Mini Site Admin Login</h3>
                                    <form className="home-register-form" onSubmit={this.handleSubmit}>
                                        <div className="col-md-12 mb10">
                                            <div className="form-group">
                                                <input type="text" className="form-control" id="name" name="email" placeholder="User Name*" onChange={this.handleChange}/>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <input type="password" className="form-control" name="password" id="password" placeholder="Password*" onChange={this.handleChange}/>
                                            </div>
                                        </div>
                                        <div className="col-md-12 sec-center">
                                        <div className="form-group">
                                            {/* <div class='email-error-msg'>{loginmessage}</div> */}
                                                <button type="submit" disabled={Cstate.length != Istate.length} className="btn btn-primary register-btn">Login</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                :
                                <div className="home-register-form-div">
                                <div className="form-logo-section sec-center">
                                    <a href="#"><img src={iconsquare} alt="logo" className="img-fluid"/></a>
                                </div>
                                <h3 className="text-capitalize">Manage Assets Admin Login</h3>
                                <form className="home-register-form" onSubmit={this.handleSubmit}>
                                    <div className="col-md-12 mb10">
                                        <div className="form-group">
                                            <input type="text" className="form-control" id="name" name="email" placeholder="User Name*" onChange={this.handleChange}/>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <input type="password" className="form-control" name="password" id="password" placeholder="Password*" onChange={this.handleChange}/>
                                        </div>
                                    </div>
                                    <div className="col-md-12 sec-center">
                                    <div className="form-group">
                                        {/* <div class='email-error-msg'>{loginmessage}</div> */}
                                            <button type="submit" disabled={Cstate.length != Istate.length} className="btn btn-primary register-btn">Login</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            }
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
export default ManageadminLogin;