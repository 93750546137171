// 'use strict';

// // App config the for development environment.
// export default {
//     app: {
//         title: 'Adidas',
//         secure: 'https',
//         host:'tshirt-mini.cns72.com', //Demo Server
//         //port:8000
//     },
//     api: {     
//         host: 'https://tshirt-mini.cns72.com/api/',
//         apiversion: "api/v1",
//         port:'3000'        
//     },   
// };
'use strict';

// App config the for development environment.
export default {
    app: {
        title: 'Adidas MakerLAB',
        secure: 'https',
        host:'tshirt-mini.cns72.com', //production Server
        //port:8000
    },
    api: {     
        host: 'https://tshirt-mini.cns72.com/api/',
        apiversion: "api/v1",
        port:'3001'        
    },   
};