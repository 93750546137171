import { Route , useParams, Redirect} from 'react-router-dom';
import React from 'react';
import Register from '../login/Register';
import Designs from '../Tshirt-selection/designs/designs';
import Vinylheattransfer from '../Tshirt-selection/designs/vinylheattransfer'
import Irononpatches from '../Tshirt-selection/designs/irononpatches'
import Handheldgun from '../Tshirt-selection/designs/handheldgun'
import Confirmation from '../Tshirt-selection/confirmation'
export default () => [
    // <Route key="login" exact path="/" component={Loginpin}/>,
    (window.location.hash == '#/admin/retail01' || window.location.hash =='#/admin/retail02' ||
    window.location.hash == '#/admin/assets/retail01' || window.location.hash == '#/admin/assets/retail02') ? window.location.href="https://www.adidas.com.sg/" : null,
    <Route key="register" exact path="/retail01" component={Register}/>,
    <Route key="register" exact path="/retail02" component={Register}/>,
    <Route key="design" path="/design-menu" render={props => (
    sessionStorage.getItem('token')
        ? <Designs {...props} />
        : <Redirect to={{ pathname: '/'+sessionStorage.getItem('store')}} />
        )} />,
    <Route key="vinyl-heat-transfer" path="/vinyl-heat-transfer" render={props => (
        sessionStorage.getItem('token')
        ? <Vinylheattransfer {...props} />
        : <Redirect to={{ pathname: '/'+sessionStorage.getItem('store')}} />
        )} />,
    <Route key="iron-on-patches" path="/iron-on-patches" render={props => (
        sessionStorage.getItem('token')
        ? <Irononpatches {...props} />
        : <Redirect to={{ pathname: '/'+sessionStorage.getItem('store')}} />
        )} />,
    <Route key="hand-held-gun" path="/hand-held-gun" render={props => (
        sessionStorage.getItem('token')
        ? <Handheldgun {...props} />
        : <Redirect to={{ pathname: '/'+sessionStorage.getItem('store')}} />
        )} />,
    <Route key="confirmation" path="/confirmation" render={props => (
        sessionStorage.getItem('token')
        ? <Confirmation {...props} />
        : <Redirect to={{ pathname: '/'+sessionStorage.getItem('store')}} />
    )} />,
];
