import { Route , useParams, Redirect} from 'react-router-dom';
import React from 'react';
import Register from '../login/Register';
import Designs from '../Tshirt-selection/designs/designs';
import Vinylheattransfer from '../Tshirt-selection/designs/vinylheattransfer'
import Irononpatches from '../Tshirt-selection/designs/irononpatches'
import Handheldgun from '../Tshirt-selection/designs/handheldgun'
import Confirmation from '../Tshirt-selection/confirmation'
import ManageadminLogin from '../admin/manageadminlogin'
import Dashboardqueing from '../admin/dashboard-queingjobs'
import Dashboardcompleted from '../admin/dashboard-completedjobs'
import Media from '../admin/media'
export default () => [
    // <Route key="login" exact path="/" component={Loginpin}/>,
    <Route key="register" exact path="/retail01" component={Register}/>,
    <Route key="register" exact path="/retail02" component={Register}/>,
    <Route key="design" path="/design-menu" render={props => (
    sessionStorage.getItem('token')
        ? <Designs {...props} />
        : <Redirect to={{ pathname: '/'+sessionStorage.getItem('store')}} />
        )} />,
    <Route key="vinyl-heat-transfer" path="/vinyl-heat-transfer" render={props => (
        sessionStorage.getItem('token')
        ? <Vinylheattransfer {...props} />
        : <Redirect to={{ pathname: '/'+sessionStorage.getItem('store')}} />
        )} />,
    <Route key="iron-on-patches" path="/iron-on-patches" render={props => (
        sessionStorage.getItem('token')
        ? <Irononpatches {...props} />
        : <Redirect to={{ pathname: '/'+sessionStorage.getItem('store')}} />
        )} />,
    <Route key="hand-held-gun" path="/hand-held-gun" render={props => (
        sessionStorage.getItem('token')
        ? <Handheldgun {...props} />
        : <Redirect to={{ pathname: '/'+sessionStorage.getItem('store')}} />
        )} />,
    <Route key="confirmation" path="/confirmation" render={props => (
        sessionStorage.getItem('token')
        ? <Confirmation {...props} />
        : <Redirect to={{ pathname: '/'+sessionStorage.getItem('store')}} />
    )} />,

    /*   admin   */
    <Route key="login" path="/admin/retail01" component={ManageadminLogin}/>,
    <Route key="login" path="/admin/retail02" component={ManageadminLogin}/>,
    <Route key="login" path="/admin/assets/retail01" component={ManageadminLogin} />,
    <Route key="login" path="/admin/assets/retail02" component={ManageadminLogin} />,
    <Route key="dashboard" path="/dashboard/queuing-jobs" render={props => (
      sessionStorage.getItem('admin_token')
           ? <Dashboardqueing {...props} />
           : <Redirect to={{ pathname: '/admin/'+sessionStorage.getItem('store')}} />
   )} />,
   <Route key="dashboard" path="/dashboard/completed-jobs" render={props => (
      sessionStorage.getItem('admin_token')
       ? <Dashboardcompleted {...props} />
       : <Redirect to={{pathname: '/admin/'+sessionStorage.getItem('store')}} />
    )} />,

    /* assets admin */

    <Route key="media" path="/admin/assets/media" render={props => (
      sessionStorage.getItem('assets_token')
           ? <Media {...props} />
           : <Redirect to={{ pathname: '/admin/assets/'+sessionStorage.getItem('store')}} />
    )} />,
   <Route key="alphabetsmedia" path="/admin/assets/media/alphabets" render={props => (
      sessionStorage.getItem('assets_token')
         ? <Media {...props} />
         : <Redirect to={{pathname: '/admin/assets/'+sessionStorage.getItem('store')}} />
    )} />,
   <Route key="addmedia" path="/admin/assets/media/add" render={props => (
      sessionStorage.getItem('assets_token')
       ? <Media {...props} />
       : <Redirect to={{ pathname: '/admin/assets/'+sessionStorage.getItem('store')}} />
    )} />,
];
